.GestureIconComponent {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.human {
    right: 0;
    top: 0;
}

.ai {
    left: 0;
    top: 0;
}


.ai img[alt="ai"],
.human img[alt="human"] {
    animation: float 3s ease-in-out infinite;
}

.icon {
    position: relative;
    z-index: 3;
    filter: invert(1);
    mix-blend-mode: exclusion;
}

.icon img {
    width: 20vw;
}

h2 {
    position: relative;
    font-size: 4vw;
    z-index: 3;
    color: #ee0045;
    /* black line around the letters */
    text-shadow: 0 0 0.1em yellow, 0 0 0.1em yellow, 0 0 0.1em yellow, 0 0 0.1em yellow;
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px) scale(1.1);
    }
    100% {
        transform: translatey(0px);
    }
    
}