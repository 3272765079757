.LivesComponent {
    position: absolute;
    right: 20%;
    top: 1.5rem;
    z-index: 5;
}

.LivesComponent span {
    font-size: 4vw;
    display: block;
    text-shadow: 0 0 2rem rgb(243, 234, 234);
    filter: invert(100%);
}