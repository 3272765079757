.BtnChallengeComponent {
    display: none;
}

.BtnChallengeComponent.show {
    display: block;
}

.BtnChallengeComponent button {
    position: relative;
    z-index: 5;
    background: rgba(215, 17, 76, 0.8);
    border: 2px solid #fef4a796;
    color: #fef4a796;
    border-radius: 10rem;
    font-family: 'Bangers', cursive;
    font-size: 2rem;
    padding: 2rem 3rem;
    display: inline-block;
    cursor: pointer !important;
    transition: all 0.3s ease-in-out;
    margin-bottom: 3rem;
}

.BtnChallengeComponent button:hover {
    background: #fef4a796;
    color: rgb(215, 17, 75);
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0 0 1rem 1rem rgba(215, 17, 75, 0.2);
}