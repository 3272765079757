.App {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  text-align: center;
  /* padding-bottom: 3rem; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h3 {
  position: absolute;
  top: 1rem;
  color: yellow;
  text-shadow: 0 0 10px black;
  z-index: 5;
  font-size: 3rem;
}

h3::before {
  content: "Player: ";
  color: white;
  margin-right: 1rem;
}

h3 .counterWins::before {
  content: "- "
}

h3 .counterWins {
  color: rgb(232, 171, 250);
  font-size: inherit;
  margin: 0;
}