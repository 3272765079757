@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');

:root {

  font-size: 16px;
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow: hidden;
  font-family: 'Bangers', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  background: url('./assets/facts-bg-blank.jpg') no-repeat center center;
  background-size: cover;
  min-height: 100vh;
}

#root { 
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
}