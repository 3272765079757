.WebcamComponent video  {
    pointer-events: none;
    /* mix-blend-mode: multiply; */
    mix-blend-mode: exclusion;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.WebcamComponent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.WebcamComponent::after {
    pointer-events: none;
    opacity: 1;
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../assets/facts-bg-overlay-blank.png') no-repeat center center;
    background-size: cover;
    z-index: 2;
}

.gradient {
    position: relative;
    z-index: 2;
}

@media (min-aspect-ratio: 1/1) {
    .gradient {
      aspect-ratio: 1/2;
      height: 100vh;
    }
    .gradientBefore {
        position: relative;
        background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%,rgba(251, 235, 130, 1) 100%);
    }
    .gradientBefore::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: linear-gradient(to right, rgb(251, 235, 130, 0) 80%,rgb(251, 235, 130) 100%);
        width: 100%;
        margin-left: -100%;
    }
    .gradientAfter {
        position: relative;
        background: linear-gradient(to left, rgba(251, 235, 130, 1) 0%,rgba(251, 235, 130, 0) 100%);
    }
    .gradientAfter::after {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to left, rgb(251, 235, 130, 0) 80%,rgb(251, 235, 130) 100%);
        width: 100%;
        margin-right: -100%;
    }
}

@media (max-aspect-ratio: 1/1) {
    .WebcamComponent {
        flex-direction: column;
    }

    .gradient {
        aspect-ratio: 2/1;
        width: 100vw;
        position: relative;
      }
      .gradientBefore {
          background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%,rgba(251, 235, 130, 1) 100%);
      }
      .gradientBefore::before {
          content: " ";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          background: linear-gradient(to bottom, rgb(251, 235, 130, 0) 80%,rgb(251, 235, 130) 100%);
          height: 100%;
          margin-top: -50%;
      }
      .gradientAfter {
          background: linear-gradient(to top, rgba(251, 235, 130, 1) 0%,rgba(251, 235, 130, 0) 100%);
      }
      .gradientAfter::after {
          content: " ";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(to top, rgb(251, 235, 130) 100%, rgb(251, 235, 130, 0) 80%);
          height: 100%;
          margin-bottom: -50%;
      } 
}

@media (aspect-ratio: 1/1) {
    .gradient {
        background-color: red;
    }
}