input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 3rem;
    font-weight: 300;
    text-align: center;
    color: #fff;
    background-color: #39003790;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    font-family: "Bangers";
    letter-spacing: 2px;
}

.show-false {
    display: none;
}

.error {
    background-color: rgba(157, 0, 0, 0.627);
}

::placeholder {
    color: rgba(255, 255, 255, 0.646);
}