.TogglePlayComponent {
    position: absolute;
    z-index: 5;
    right: 0;
    bottom: 0;
    padding: 1rem;
    background-color: #ffd761d0;
    display: flex;
    align-items: center;
}

span {
    font-size: 1.2rem;
    margin-left: 1rem;
}

.checked-true {
    display: none;
}